import type { DynamicField } from '@/types/graphql'
import { DynamicFieldControl, DynamicFieldQuery, DynamicFieldType } from '@/types/graphql'

import { useEffect, useState } from 'react'
import { withApollo } from 'react-apollo'
import { DatePicker, Input } from 'antd'

import DynamicInputText from '@/components/Booking/DynamicField/DynamicInputText'
import {
  DYNAMIC_FIELD_DATE_FORMAT,
  getDynamicFieldInitialValue,
  getDynamicFieldLookupKey,
  getDynamicFieldQuery
} from '@/components/Booking/DynamicField/helper'
import PlaceSelector from '@/components/Booking/DynamicField/PlaceSelector'
import PortPlaceSelector from '@/components/Booking/DynamicField/PortPlaceSelector'
import SelectWithQuery from '@/components/Booking/DynamicField/SelectWithQuery'
import UploadDocument from '@/components/Booking/DynamicField/UploadDocument'
import { FormMode } from '@/components/Manage/Shared/CrudType/Form'
import EnumSelector from '@/components/Shared/EnumSelector'
import TagsInput from '@/components/Shared/TagsInput/TagsInput'

type BookingDynamicFieldProps = {
  form: any
  mode: FormMode
  dynamicField: any
  duplicateBookingObject?: any
  client?: any
  emptyDate?: boolean
}

const BookingDynamicField = (props: BookingDynamicFieldProps) => {
  const { form, duplicateBookingObject, dynamicField, client, mode, emptyDate } = props

  const [query, setQuery] = useState(getDynamicFieldQuery(dynamicField))

  useEffect(() => {
    if (dynamicField.query !== DynamicFieldQuery.Quotation) return

    const billToUuid = form.getFieldValue('billToUuid')

    if (!billToUuid) return

    setQuery(prev => ({ ...prev, variables: { ...prev.variables, buyerUuid: billToUuid } }))
  }, [form, dynamicField])

  const renderField = (props: DynamicField) => {
    const { key, query: queryType, type, customValues, multiple, isMultiline, isCapitalize } = props

    const defaultRule = {
      required: dynamicField.control === DynamicFieldControl.Required,
      message: `${key} is required`
    }

    const fieldKey = getDynamicFieldLookupKey(key || '', mode, type, duplicateBookingObject)

    switch (type) {
      case DynamicFieldType.Selector:
        return form.getFieldDecorator(fieldKey, {
          rules: [defaultRule],
          initialValue: getDynamicFieldInitialValue(
            fieldKey,
            dynamicField,
            duplicateBookingObject,
            mode
          )
        })(
          (() => {
            switch (queryType) {
              case DynamicFieldQuery.Place:
                return <PlaceSelector />
              case DynamicFieldQuery.Port:
                return <PortPlaceSelector mode={mode} />
              case DynamicFieldQuery.Enums:
                return (
                  <EnumSelector
                    client={client}
                    placeholder="Select a value..."
                    enumName={dynamicField.enumName}
                    multiple={dynamicField.multiple}
                  />
                )
              default: {
                if (queryType === DynamicFieldQuery.CustomValues && !customValues) {
                  return <TagsInput placeholder={`Enter ${key}`} />
                }

                return (
                  <SelectWithQuery
                    fieldKey={fieldKey}
                    query={query}
                    options={
                      queryType === DynamicFieldQuery.CustomValues
                        ? customValues?.split('\n') || []
                        : undefined
                    }
                    mode={
                      queryType === DynamicFieldQuery.CustomValues && !customValues
                        ? 'tags'
                        : multiple
                          ? 'multiple'
                          : 'default'
                    }
                    autoSelectFirst={queryType === DynamicFieldQuery.Quotation}
                  />
                )
              }
            }
          })()
        )
      case DynamicFieldType.Date:
        return form.getFieldDecorator(fieldKey, {
          rules: [defaultRule],
          initialValue: getDynamicFieldInitialValue(
            fieldKey,
            dynamicField,
            duplicateBookingObject,
            mode,
            emptyDate
          )
        })(
          <DatePicker
            showTime
            format={DYNAMIC_FIELD_DATE_FORMAT}
            disabled={mode === FormMode.Edit}
            placeholder="Select a date and time"
            // @ts-ignore
            getCalendarContainer={trigger => trigger.parentNode}
          />
        )
      case DynamicFieldType.Document:
        return form.getFieldDecorator(fieldKey, {
          rules: [defaultRule],
          initialValue: getDynamicFieldInitialValue(
            fieldKey,
            dynamicField,
            duplicateBookingObject,
            mode
          )
        })(
          <UploadDocument
            fieldKey={fieldKey}
            data={form.getFieldValue(fieldKey)}
            setFieldsValue={form.setFieldsValue}
            isUploadable={mode !== FormMode.Edit}
            handleDocumentUpload={(acceptedFiles: File) => acceptedFiles}
          />
        )

      default: {
        return form.getFieldDecorator(fieldKey, {
          rules: [defaultRule],
          initialValue: getDynamicFieldInitialValue(
            fieldKey,
            dynamicField,
            duplicateBookingObject,
            mode
          )
        })(
          isMultiline ? (
            <Input.TextArea rows={4} autoComplete="off" placeholder={`Enter ${key}`} />
          ) : (
            <DynamicInputText isCapitalize={!!isCapitalize} />
          )
        )
      }
    }
  }

  return renderField(dynamicField)
}

export default withApollo(BookingDynamicField)
