import type { CreateTripInput, JobTripUpdateInput, JobType, JobTypeDetails } from '@/types/graphql'
import { AddressType, JobTypeRequiredFieldControl, TripStatus } from '@/types/graphql'

import { ChangeEvent, memo, useCallback, useState } from 'react'
import { withApollo } from 'react-apollo'
import { ApolloClient, useMutation } from '@apollo/client'
import { Button, Col, Form, Input, Popconfirm, Row, Select, Tooltip, Typography } from 'antd'
import { t } from 'i18next'
import { cloneDeep, pick, startCase } from 'lodash'
import { v4 } from 'uuid'

import { UPDATE_TRIPS_MUTATION } from '@/components/Booking/General/schema'
import { FormMode } from '@/components/Manage/Shared/CrudType/Form'
import AddressSelect from '@/components/Select/AddressSelect'
import CompanySelect from '@/components/Select/TypeToFetch/CompanySelect'
import DynamicField from '@/components/Shared/DynamicField'
import { useBookingStore } from '@/store/booking'
import useGlobalCompanyStore from '@/store/globalCompany'
import responseHandler from '@/utils/responseHandler'
import { generateSixCharacterString } from '@/utils/u'
import { ADD_TRIP, DELETE_TRIP } from './schema'
import styles from './Trip.module.css'

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 }
}

interface TripProps {
  client?: ApolloClient<any>
  jobUuid: string
  index: number
  onChange: any
  format: string
  jobType: JobType
  requiredFields: any
  numberOfTrips: number
  trips: JobTripUpdateInput[]
  tripDetail: JobTypeDetails | undefined
  mode: FormMode
}

const Trip = memo(
  ({
    mode,
    index,
    client,
    format,
    jobUuid,
    onChange,
    tripDetail,
    trips = [{}],
    numberOfTrips,
    requiredFields = []
  }: TripProps) => {
    const [createTripMutation] = useMutation(ADD_TRIP, { client })
    const [deleteTripMutation] = useMutation(DELETE_TRIP, { client })
    const [updateTripsMutation] = useMutation(UPDATE_TRIPS_MUTATION, { client })

    // Currently I think the issue comes from toFromCompany is not set because it's stored in a state... need to change this.
    const tripUuid = trips[index]?.uuid
    const [fromCompanyUuid, setFromCompanyUuid] = useState<string>(trips[index]?.fromCompanyUuid)
    const [toCompanyUuid, setToCompanyUuid] = useState<string>(trips[index]?.toCompanyUuid)
    const [references, setReferences] = useState(trips[index]?.references || [])
    const [remarks, setRemarks] = useState<string>(trips[index]?.remarks || '')
    const [fromUuid, setFromUuid] = useState<string>(trips[index]?.fromUuid)
    const [toUuid, setToUuid] = useState<string>(trips[index]?.toUuid)
    const [sealNo, setSealNo] = useState(trips[index]?.seal || '')

    const tripDynamicFields = useBookingStore.use.tripDynamicFields()
    const setTripDynamicFields = useBookingStore.use.setTripDynamicFields()
    const selectedGlobalCompany = useGlobalCompanyStore.use.selectedGlobalCompany()

    const isSameStartFormat = format === 'sameStart'
    const isSameEndFormat = format === 'sameEnd'
    const isLinearFormat = format === 'linear'
    const isNoneFormat = format === 'none'

    const onDuplicateTrip = async (index: number) => {
      const newArray = cloneDeep(trips)
      const targetDuplicate = newArray[index]

      const sanitisedTrip = {
        ...targetDuplicate,
        // @ts-expect-error
        sequence: newArray[index].sequence + 1,
        ownerUuid: selectedGlobalCompany?.uuid,
        waybillId: generateSixCharacterString()
      }

      Array.isArray(sanitisedTrip.details) && delete sanitisedTrip.details

      delete sanitisedTrip.status
      delete sanitisedTrip.uuid
      // @ts-ignore
      delete sanitisedTrip.to
      // @ts-ignore
      delete sanitisedTrip.from
      // @ts-ignore
      delete sanitisedTrip.__typename

      if (mode === FormMode.Edit) {
        try {
          await createTripMutation({ variables: { input: { ...sanitisedTrip } } })
        } catch (error: any) {
          responseHandler(error.message, 'error')
        }
      }

      newArray.splice(index + 1, 0, { ...targetDuplicate, uuid: v4() })

      onChange(newArray)
    }

    const onAddTrip = async (index: number) => {
      const newArray = cloneDeep(trips)

      const isAddLastTrip = index === trips.length - 1
      const isAddMiddleTrip = index !== trips.length - 1
      let sequence: number = 0
      const input: CreateTripInput = {
        jobUuid
      }

      if (isSameStartFormat) {
        newArray.splice(index + 1, 0, {
          uuid: v4(),
          type: newArray[index].type,
          fromUuid: newArray[index].fromUuid,
          fromCompanyUuid: newArray[index].fromCompanyUuid
        })

        sequence = index++
        input.type = newArray[index].type
        input.fromUuid = newArray[index].fromUuid
        input.fromCompanyUuid = newArray[index].fromCompanyUuid
      }

      if (isSameEndFormat) {
        newArray.splice(index + 1, 0, {
          uuid: v4(),
          type: newArray[index].type,
          toUuid: newArray[index].toUuid,
          toCompanyUuid: newArray[index].toCompanyUuid
        })

        sequence = index++
        input.type = newArray[index].type
        input.toUuid = newArray[index].toUuid
        input.toCompanyUuid = newArray[index].toCompanyUuid
      }

      if (isLinearFormat) {
        if (isAddLastTrip) {
          newArray.splice(index + 1, 0, {
            uuid: v4(),
            type: newArray[index].type,
            fromUuid: newArray[index].toUuid,
            fromCompanyUuid: newArray[index].toCompanyUuid
          })

          sequence = index++
          input.type = newArray[index].type
          input.fromUuid = newArray[index].toUuid
          input.fromCompanyUuid = newArray[index].toCompanyUuid
        }

        if (isAddMiddleTrip) {
          newArray.splice(index + 1, 0, {
            uuid: v4(),
            type: newArray[index].type,
            fromUuid: newArray[index].toUuid,
            fromCompanyUuid: newArray[index].toCompanyUuid
          })

          sequence = index++
          input.type = newArray[index].type
          input.fromUuid = newArray[index].toUuid
          input.fromCompanyUuid = newArray[index].toCompanyUuid

          newArray.map((t, i) => {
            if (i === index + 2) {
              t.fromUuid = undefined
              t.fromCompanyUuid = undefined
            }
            return t
          })
        }
      }

      if (isNoneFormat) {
        newArray.splice(index + 1, 0, {
          uuid: v4(),
          type: newArray[index].type
        })

        sequence = index++
        input.type = newArray[index].type
      }

      if (mode === FormMode.Edit) {
        input.sequence = sequence
        input.status = TripStatus.Pending
        newArray[index].uuid = v4()
      }
      onChange(newArray)
    }

    const onRemove = useCallback(
      async (index: number) => {
        let newTrips = [...trips]

        newTrips = newTrips.map((t, i) => {
          if (i === index + 1) {
            t.fromUuid = newTrips[index - 1].toUuid
            t.fromCompanyUuid = newTrips[index - 1].toCompanyUuid
            t.fromUuid = newTrips[index].fromUuid
            t.fromCompanyUuid = newTrips[index].fromCompanyUuid
          }
          return t
        })

        const removeTrip = newTrips.filter((t, tripIndex) => tripIndex !== index)
        onChange(removeTrip)

        if (mode === FormMode.Edit) {
          const res = await deleteTripMutation({ variables: { uuid: tripUuid } })
          if (!res.data?.deleteTrip?.success) {
            return responseHandler(res.data?.deleteTrip?.message, 'error')
          }
        }
      },

      // eslint-disable-next-line react-hooks/exhaustive-deps
      [onChange, trips]
    )

    const onUp = (index: number) => {
      const newArray = cloneDeep(trips)

      const newnewArray = newArray.map((t, i) => {
        if (i === index - 1) {
          t.fromUuid = newArray[index].fromUuid
          t.fromCompanyUuid = newArray[index].fromCompanyUuid
          t.toUuid = trips[index - 1].fromUuid
          t.toCompanyUuid = trips[index - 1].fromCompanyUuid
        }
        if (i === index) {
          t.fromUuid = newArray[index - 1].toUuid
          t.fromCompanyUuid = newArray[index - 1].toCompanyUuid
        }
        if (i === index - 2) {
          t.toUuid = trips[index].fromUuid
          t.toCompanyUuid = trips[index].fromCompanyUuid
        }
        return t
      })

      onChange(newnewArray)
    }

    const onDown = (index: number) => {
      const newArray = cloneDeep(trips)

      const newnewArray = newArray.map((t, i) => {
        if (i === index - 1) {
          t.toUuid = trips[index + 1].fromUuid
          t.toCompanyUuid = trips[index + 1].fromCompanyUuid
        }
        if (i === index) {
          t.toUuid = trips[index].fromUuid
          t.toCompanyUuid = trips[index].fromCompanyUuid
          t.fromUuid = newArray[index + 1].fromUuid
          t.fromCompanyUuid = newArray[index + 1].fromCompanyUuid
        }
        if (i === index + 1) {
          t.fromUuid = trips[index].fromUuid
          t.fromCompanyUuid = trips[index].fromCompanyUuid
        }
        return t
      })

      onChange(newnewArray)
    }

    const onUpRight = (index: number) => {
      const newArray = cloneDeep(trips)
      const newnewArray = newArray.map((t, i) => {
        if (i === index - 1) {
          t.toUuid = newArray[index].toUuid
          t.toCompanyUuid = newArray[index].toCompanyUuid
        }
        if (i === index) {
          t.fromUuid = newArray[index].toUuid
          t.fromCompanyUuid = newArray[index].toCompanyUuid
          t.toUuid = trips[index - 1].toUuid
          t.toCompanyUuid = trips[index - 1].toCompanyUuid
        }
        return t
      })

      onChange(newnewArray)
    }

    const onDownRight = (index: number) => {
      const newArray = cloneDeep(trips)
      const newnewArray = newArray.map((t, i) => {
        if (i === index) {
          t.toUuid = newArray[index + 1].toUuid
          t.toCompanyUuid = newArray[index + 1].toCompanyUuid
        }
        if (i === index + 1) {
          t.fromUuid = newArray[index].toUuid
          t.fromCompanyUuid = newArray[index].toCompanyUuid
          t.toUuid = trips[index].toUuid
          t.toCompanyUuid = trips[index].toCompanyUuid
        }
        if (i === index + 2) {
          t.fromUuid = newArray[index + 1].toUuid
          t.fromCompanyUuid = newArray[index + 1].toCompanyUuid
        }
        return t
      })

      onChange(newnewArray)
    }

    const onSetCompanyUuid = (index: number, type: string, uuid: string) => {
      if (format === 'sameStart' && type === 'from') {
        trips.forEach((trip: JobTripUpdateInput) => (trip.fromCompanyUuid = uuid))
        setFromCompanyUuid(uuid)
        trips[index].fromCompanyUuid = uuid
      } else if (format === 'sameEnd' && type === 'to') {
        trips.forEach((trip: JobTripUpdateInput) => (trip.toCompanyUuid = uuid))
        setToCompanyUuid(uuid)
        trips[index].toCompanyUuid = uuid
      }
      if (type === 'to') {
        setToCompanyUuid(uuid)
        trips[index].toCompanyUuid = uuid
        if (format === 'linear' && index !== trips.length - 1) {
          trips[index + 1].fromCompanyUuid = uuid
        }
      } else {
        setFromCompanyUuid(uuid)
        trips[index].fromCompanyUuid = uuid
        if (format === 'linear' && index !== 0) {
          trips[index - 1].toCompanyUuid = uuid
        }
      }
      onChange(trips)
    }

    const onSetAddressUuid = (index: number, type: string, uuid: string) => {
      if (format === 'sameStart' && type === 'from') {
        trips.forEach((trip: JobTripUpdateInput) => (trip.fromUuid = uuid))
        setFromUuid(uuid)
        trips[index].fromUuid = uuid
      } else if (format === 'sameEnd' && type === 'to') {
        trips.forEach((trip: JobTripUpdateInput) => (trip.toUuid = uuid))
        setToUuid(uuid)
        trips[index].toUuid = uuid
      } else if (type === 'to') {
        setToUuid(uuid)
        trips[index].toUuid = uuid
        // sync To address with next trip From address
        if (format === 'linear' && trips[index + 1]) {
          trips[index + 1].fromUuid = uuid
        }
      } else {
        setFromUuid(uuid)
        trips[index].fromUuid = uuid
        // sync From address with the next trips To address
        if (format === 'linear' && trips[index - 1]) {
          trips[index - 1].toUuid = uuid
        }
      }
      onChange(trips)
    }

    const onSetSealNo = (e: ChangeEvent<HTMLInputElement>) => {
      setSealNo(e.target.value)
      trips[index].seal = e.target.value
      onChange(trips)
    }

    const onSetRemarks = (e: ChangeEvent<HTMLInputElement>) => {
      setRemarks(e.target.value)
      trips[index].remarks = e.target.value
    }

    const onSetReferences = (value: any[]) => {
      setReferences(value)
      trips[index].references = value
      onChange(trips)
    }

    const onSetDynamicFieldValue = (value: any, dynamicFieldKey: string) => {
      const details = {
        ...trips[index].details,
        [dynamicFieldKey]: value
      }
      trips[index].details = details
      // Trigger a targetted re-render only for the dynamic fields by
      // mimicking what the other onChange functions are doing.
      setTripDynamicFields([...tripDynamicFields])
    }

    const undeleteTrip = () => {
      const newTrip = cloneDeep(trips)
      newTrip[index].status = TripStatus.Pending
      onChange(newTrip)
    }

    const createTrip = async () => {
      const newTrips = cloneDeep(trips)

      const nextTripSequence = index + 1

      const input = {
        ...newTrips[index],
        jobUuid,
        sequence: index + 1,
        ownerUuid: selectedGlobalCompany?.uuid,
        waybillId: generateSixCharacterString()
      }

      delete input.uuid

      try {
        const result = await createTripMutation({ variables: { input } })

        newTrips[index] = { ...input }

        if (result.data?.createTrip) {
          responseHandler('Trip created successfully', 'success')

          if (isNoneFormat) return onChange(newTrips)

          const findResultTrip = result.data.createTrip.filter(
            t => t?.sequence === nextTripSequence
          )?.[0]

          if (isLinearFormat) {
            newTrips[index] = {
              ...newTrips[index],
              uuid: findResultTrip?.uuid
            }
            newTrips.map((t, i) => {
              if (i > index + 1) {
                return {
                  ...t,
                  // @ts-expect-error
                  sequence: t + 1
                }
              }
            })

            const tripsInput = newTrips?.map(trip => {
              return {
                ...pick(trip, [
                  'uuid',
                  'fromUuid',
                  'toUuid',
                  'remarks',
                  'references',
                  'seal',
                  'status',
                  'type',
                  'details'
                ])
              }
            })

            await updateTripsMutation({
              variables: {
                input: {
                  jobUuid,
                  trips: tripsInput
                }
              }
            })
          }

          onChange(newTrips)
        }
      } catch (error: any) {
        responseHandler(error.message, 'error')
      }
    }

    // show or hide button logic
    const isDownButtonVisible = trips.length > 1 && !(trips.length === index + 1)

    const isUpButtonVisible = index !== 0

    const canAddTrip = numberOfTrips === 0 ? true : trips.length < numberOfTrips

    const isDeleted = trips[index].status === TripStatus.Deleted

    const isNewTrip = !trips[index].status

    return (
      <Row
        id={`trips #${index} container`}
        style={{
          marginTop: 10,
          padding: isDeleted ? '10px 5px' : undefined,
          backgroundColor: isDeleted ? '#F0F0F0' : undefined
        }}
      >
        <Typography.Text strong style={{ marginLeft: -60 }}>
          {tripDetail ? tripDetail.type : `Trip #${index + 1}`}
        </Typography.Text>

        <Row>
          <Col span={12}>
            <Form.Item label="From Company" {...formItemLayout} required={true}>
              <CompanySelect
                formId={`trips-${index}-from-company-selector`}
                quickCreate
                disabled={isDeleted}
                value={fromCompanyUuid}
                style={{ width: '100%' }}
                types={tripDetail?.fromCompanyTypes || ['shipperConsignee']}
                onChange={(uuid: string) => onSetCompanyUuid(index, 'from', uuid)}
              />
            </Form.Item>

            <Form.Item label="From Address" {...formItemLayout} required={true}>
              <AddressSelect
                value={fromUuid}
                disabled={isDeleted}
                style={{ width: '100%' }}
                companyId={fromCompanyUuid}
                quickAdd={!!fromCompanyUuid}
                companyUuid={fromCompanyUuid}
                type={[AddressType.Delivery, AddressType.Warehouse]}
                onChange={(uuid: string) => onSetAddressUuid(index, 'from', uuid)}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="To Company" {...formItemLayout} required={true}>
              <CompanySelect
                formId={`trips-${index}-to-company-selector`}
                quickCreate
                disabled={isDeleted}
                value={toCompanyUuid}
                style={{ width: '100%' }}
                types={tripDetail?.toCompanyTypes || ['shipperConsignee']}
                onChange={(uuid: string) => onSetCompanyUuid(index, 'to', uuid)}
              />
            </Form.Item>
            <Form.Item label="To Address" {...formItemLayout} required={true}>
              <AddressSelect
                value={toUuid}
                disabled={isDeleted}
                style={{ width: '100%' }}
                companyId={toCompanyUuid}
                quickAdd={!!toCompanyUuid}
                companyUuid={toCompanyUuid}
                type={[AddressType.Delivery, AddressType.Warehouse]}
                onChange={(uuid: string) => onSetAddressUuid(index, 'to', uuid)}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            {requiredFields?.tripSeal && (
              <Form.Item
                label="Seal No"
                {...formItemLayout}
                required={
                  requiredFields?.tripSeal?.control === JobTypeRequiredFieldControl.Required
                }
              >
                <Input
                  value={sealNo}
                  disabled={isDeleted}
                  onChange={onSetSealNo}
                  style={{ width: '100%' }}
                  placeholder={'Enter seal number...'}
                />
              </Form.Item>
            )}

            {requiredFields?.tripReferences && (
              <Form.Item
                label="References"
                {...formItemLayout}
                required={
                  requiredFields?.tripReferences?.control === JobTypeRequiredFieldControl.Required
                }
              >
                <Select
                  mode="tags"
                  value={references}
                  disabled={isDeleted}
                  style={{ width: '100%' }}
                  onChange={onSetReferences}
                  placeholder="Enter references..."
                />
              </Form.Item>
            )}
          </Col>
        </Row>

        {tripDynamicFields.length > 0 ? (
          <Row>
            <Col>
              {tripDynamicFields.map(field => {
                return (
                  <Form.Item
                    key={field.key}
                    label={startCase(field.key)}
                    {...{ labelCol: { span: 3 }, wrapperCol: { span: 21 } }}
                    required={field.control === JobTypeRequiredFieldControl.Required}
                  >
                    <DynamicField
                      field={field}
                      value={trips[index]?.details?.[field.key]}
                      onChange={(value: any) => onSetDynamicFieldValue(value, field.key)}
                    />
                  </Form.Item>
                )
              })}
            </Col>
          </Row>
        ) : null}

        <Form.Item
          label={t('common.remarks')}
          {...{ labelCol: { span: 3 }, wrapperCol: { span: 21 } }}
        >
          <Input
            value={remarks}
            autoComplete="off"
            disabled={isDeleted}
            onChange={onSetRemarks}
            placeholder={`Enter trip #${index + 1} remarks...`}
          />
        </Form.Item>

        <div id="trips-button-container" className={styles.tripButtonsContainer}>
          {isLinearFormat && (
            <div className={styles.rearrangeContainer}>
              <div className={styles.gap}>
                {isUpButtonVisible && (
                  <Button disabled={isDeleted} icon="up" onClick={() => onUp(index)} />
                )}
                {isDownButtonVisible && (
                  <Button disabled={isDeleted} icon="down" onClick={() => onDown(index)} />
                )}
              </div>

              <div className={styles.gap}>
                {isUpButtonVisible && (
                  <Button disabled={isDeleted} icon="up" onClick={() => onUpRight(index)} />
                )}
                {isDownButtonVisible && (
                  <Button disabled={isDeleted} icon="down" onClick={() => onDownRight(index)} />
                )}
              </div>
            </div>
          )}

          <div className={styles.addMinusTrip}>
            {canAddTrip && (
              <div className={styles.tripButtonsContainer}>
                <span style={{ fontSize: '0.8em', opacity: 0.6 }}>{t('common.trip')}:</span>
                <Tooltip title={t('booking.duplicateTrip')} mouseEnterDelay={0.7}>
                  <Popconfirm
                    okText={t('common.yes')}
                    cancelText={t('common.no')}
                    title={t('common.duplicateTrip')}
                    onConfirm={() => onDuplicateTrip(index)}
                  >
                    <Button id="trip-duplicate-button" icon="copy" disabled={isDeleted} />
                  </Popconfirm>
                </Tooltip>

                <Tooltip title={t('common.addTrip')} mouseEnterDelay={0.7}>
                  <Button
                    icon="plus"
                    id="trip-add-button"
                    disabled={isDeleted}
                    onClick={() => onAddTrip(index)}
                  />
                </Tooltip>
              </div>
            )}

            {index >= 1 &&
              (isNewTrip ? (
                <Tooltip title={t('common.deleteTrip')} mouseEnterDelay={0.7}>
                  <Button
                    icon="minus"
                    type="dashed"
                    disabled={isDeleted}
                    onClick={() => onRemove(index)}
                  />
                </Tooltip>
              ) : (
                <Popconfirm
                  okText={t('common.yes')}
                  cancelText={t('common.no')}
                  title={t('common.deleteTrip')}
                  onConfirm={() => onRemove(index)}
                >
                  <Tooltip title={t('common.deleteTrip')} mouseEnterDelay={0.7}>
                    <Button icon="minus" disabled={isDeleted} type="dashed" />
                  </Tooltip>
                </Popconfirm>
              ))}
          </div>

          {isDeleted && (
            <Popconfirm
              okText={t('common.yes')}
              cancelText={t('common.no')}
              onConfirm={() => undeleteTrip()}
              title={t('instructions.areYouSure')}
            >
              <Button type="danger">{t('booking.undeleteTrip')}</Button>
            </Popconfirm>
          )}

          {isNewTrip && mode === FormMode.Edit && (
            <Popconfirm
              title={'Add trip?'}
              okText={t('common.yes')}
              cancelText={t('common.no')}
              onConfirm={() => createTrip()}
            >
              <Button type="primary">{t('common.create')}</Button>
            </Popconfirm>
          )}
        </div>
      </Row>
    )
  }
)

export default withApollo(Trip)
