import type { FormComponentProps } from 'antd/es/form'
import type { Job, JobType } from 'App/types/graphql'
import { DynamicFieldType, JobTypeRequiredFieldControl, TripFormat } from 'App/types/graphql'

import { memo, useCallback, useEffect, useState } from 'react'
import { withApollo } from 'react-apollo'
import { ApolloClient } from '@apollo/client'
import { Col, Form, Input, Row } from 'antd'
import { startCase } from 'lodash'
import cloneDeep from 'lodash/cloneDeep'
import keyBy from 'lodash/keyBy'

import JobTypeSelector from '@/components/Select/JobTypeSelector'
import { FormMode } from 'App/components/Manage/Shared/CrudType/Form'
import DynamicField from 'App/components/Shared/DynamicFieldComponent'
import EnumSelector from 'App/components/Shared/EnumSelector'
import { isValidContainerNumber } from 'App/components/Transport/Utils/jobHelper'
import useJobTypes from 'App/hooks/useJobTypes'
import { useBookingStore } from 'App/store/booking'
import Trips from './Trips'

const multiDropLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 }
  }
}

// Use this when you're flexing two form items in a row on a job level.
const formItemDividedLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  }
}

export enum EditMode {
  TRIPS = 'trips',
  CREATE = 'create',
  DETAILS = 'details'
}

export type JobPreSubmitProps = Job & {
  key: string
  tripFormat: TripFormat
}

interface JobFormProps extends FormComponentProps {
  mode?: FormMode
  client?: ApolloClient<any>
  updateJobs: any
  jobIndex: number
  editMode: EditMode
  allJobs: JobPreSubmitProps[]
  currentJob: JobPreSubmitProps
  selectedCompany?: string
}

// this component is being called from src/App/pages/NewBookingTransport/NewBookingTransportForm/TransportDetailsSubForm/JobsForm/index.js
const JobForm = memo((props: JobFormProps) => {
  const {
    form,
    client,
    allJobs,
    jobIndex,
    currentJob,
    updateJobs = () => {},
    mode = FormMode.Create
  } = props
  const { getFieldDecorator } = form

  const [isMounted, setIsMounted] = useState<boolean>(false)
  const [dynamicFields, setDynamicFields] = useState<any>([])
  const [tripsRenderCount, setTripsRenderCount] = useState(0)
  const [jobType, setJobType] = useState<JobType | null>(null)
  const [requiredFields, setRequiredFields] = useState<any>([])
  const [tripFormat, setTripFormat] = useState<TripFormat>(currentJob?.tripFormat)
  const [containerNoFontColor, setContainerNoFontColor] = useState<string>('black')

  const { jobTypes, requestJobTypes } = useJobTypes(client)

  const setTripDynamicFields = useBookingStore.use.setTripDynamicFields()
  const selectedBillToCompany = useBookingStore.use.selectedBillToCompany()
  const resetTripDynamicFields = useBookingStore.use.resetTripDynamicFields()
  const selectedBookingTypeCode = useBookingStore.use.selectedBookingTypeCode()

  useEffect(() => {
    setIsMounted(true)
    return () => {
      setIsMounted(false)
    }
  }, [])

  useEffect(() => {
    return () => {
      resetTripDynamicFields()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setJobType(jobTypes?.[0])
  }, [jobTypes])

  useEffect(() => {
    if (!jobType) {
      return
    }

    const { trips } = form.getFieldsValue()

    const tripsToPopulate = jobType?.tripDetails?.length
      ? jobType?.tripDetails?.map((t: any, i: number) => trips?.[i] || {})
      : trips || [{}]
    form.setFieldsValue({
      trips: tripsToPopulate,
      type: jobType.code
    })

    setRequiredFields(keyBy(jobType.requiredFields, 'name'))
    setDynamicFields(jobType.dynamicFields || [])
    setTripDynamicFields(jobType.tripDynamicFields || [])

    if (!jobType.tripDetails?.length) return

    const currentTrips = form.getFieldValue('trips') || []

    const tripDetailsLength = jobType.tripDetails.length

    if (currentTrips.length > tripDetailsLength) {
      const diff = currentTrips.length - tripDetailsLength
      currentTrips.length -= diff

      form.setFieldsValue({ trips: currentTrips })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobType])

  const handleJobChange = useCallback(() => {
    const currentJobValues = form.getFieldsValue()

    if (
      (currentJobValues.type && !jobTypes) ||
      !jobTypes.find((jt: JobType) => jt.code === currentJobValues.type)
    ) {
      requestJobTypes({ codes: [currentJobValues.type] })
    }

    if (currentJobValues.tripFormat) {
      setTripFormat(currentJobValues.tripFormat)
    }

    allJobs[jobIndex] = { ...currentJob, ...currentJobValues }

    updateJobs(allJobs)

    const timeoutId = setTimeout(() => {
      isMounted && setTripsRenderCount(prev => prev + 1)
    }, 200)

    return () => {
      clearTimeout(timeoutId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allJobs, form, jobIndex])

  const handelTripsChange = trips => {
    const newTrips = cloneDeep(trips)
    currentJob.trips = newTrips
    form.setFieldsValue({ trips: newTrips })
    handleJobChange()
  }

  useEffect(() => {
    handleJobChange()
  }, [handleJobChange, form])

  return (
    <>
      <Row>
        <Col span={12}>
          <Form.Item label="Job Type" {...formItemDividedLayout}>
            {getFieldDecorator('type', {
              initialValue: currentJob?.type,
              rules: [{ required: true, message: 'Job type is required.' }]
            })(
              // @ts-expect-error
              <JobTypeSelector
                defaultActiveFirstOption
                queryVariables={{ byCompany: selectedBillToCompany }}
                bookingTypeCode={selectedBookingTypeCode}
              />
            )}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Trip Order Format" {...formItemDividedLayout}>
            {getFieldDecorator('tripFormat', {
              initialValue: currentJob?.tripFormat || TripFormat.None
            })(
              <EnumSelector
                camelCaseToNormal
                enumName="TripFormat"
                placeholder="Select Trip Format"
              />
            )}
          </Form.Item>
        </Col>
      </Row>

      {dynamicFields.length > 0 ? (
        <Row>
          <Col>
            {dynamicFields.map((field: any) => {
              let dynamicFieldRules = [
                {
                  required: field.control === JobTypeRequiredFieldControl.Required,
                  message: `${field.key} is required`
                }
              ]

              if (field?.isValidateContainerNumber) {
                dynamicFieldRules = [
                  ...dynamicFieldRules,
                  {
                    // @ts-expect-error
                    validator: (_: unknown, val: string) => {
                      if (!val) {
                        setContainerNoFontColor('black')
                      } else if (isValidContainerNumber(val)) {
                        setContainerNoFontColor('green')
                      } else if (val.length > 11) {
                        setContainerNoFontColor('red')
                      } else {
                        setContainerNoFontColor('#DAA520')
                      }
                    }
                  }
                ]
              }
              return (
                <Form.Item key={field.key} label={startCase(field.key)} {...multiDropLayout}>
                  {getFieldDecorator(`details.${field.key}`, {
                    initialValue: currentJob?.details?.[field.key],
                    rules: dynamicFieldRules
                  })(
                    <DynamicField
                      id={field.key}
                      field={field}
                      textColor={
                        field?.isValidateContainerNumber ? containerNoFontColor : undefined
                      }
                      disabled={mode === FormMode.Edit && field.type === DynamicFieldType.Date}
                    />
                  )}
                </Form.Item>
              )
            })}
          </Col>
        </Row>
      ) : null}

      <Row>
        <Col>
          <Form.Item label="Remarks" {...multiDropLayout}>
            {getFieldDecorator('remarks', { initialValue: currentJob?.remarks })(
              <Input autoComplete="off" placeholder="Enter job remarks..." />
            )}
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Form.Item label=" " colon={false} {...multiDropLayout}>
          {getFieldDecorator('trips', { initialValue: currentJob?.trips || [{}] })(
            <Trips
              jobUuid={currentJob?.uuid}
              jobType={jobType || {}}
              onChange={handelTripsChange}
              requiredFields={requiredFields}
              format={tripFormat || currentJob?.tripFormat}
              tripsRenderCount={tripsRenderCount}
              mode={mode}
            />
          )}
        </Form.Item>
      </Row>
    </>
  )
})

// @ts-ignore
export default withApollo(Form.create<FormComponentProps>()(JobForm))
